import { Injectable } from '@angular/core';

class LocalStorage implements Storage {
  [name: string]: any;

  readonly length: number = 0;

  clear(): void {
  }

  getItem(key: string): string | null {
    return null;
  }

  key(index: number): string | null {
    return null;
  }

  removeItem(key: string): void {
  }

  setItem(key: string, value: string): void {
  }
}


@Injectable({
  providedIn: 'root'
})
export class StorageService implements Storage {

  private _storage: Storage;

  constructor() {
    this._storage = localStorage;
  }

  [name: string]: any;

  length: number = 0;

  clear(): void {
    this._storage.clear();
  }

  getItem(key: string): string | null {
    return this._storage.getItem(key);
  }

  key(index: number): string | null {
    return this._storage.key(index);
  }

  removeItem(key: string): void {
    return this._storage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    return this._storage.setItem(key, value);
  }
}
