import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError } from 'rxjs';
import { AuthService } from "../../services/auth/auth.service";

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
    const authService = inject(AuthService);
    const token = authService.getToken();
    const reqClone = req.clone({
        setHeaders: {
            Authorization: `Bearer ${token}`,
        },
    });
    return next(reqClone).pipe(
        catchError((err) => {
            if (err.status === 401) {
                authService.logout();
            }
            throw err;
        })
    );
};
