import { Component, ViewChild } from '@angular/core';
import { LoaderService } from "../../services/loader/loader.service";
import { CommonModule } from "@angular/common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { filter, tap } from "rxjs";
import { ILoader } from "../../services/loader/loader.interface";
import { IonLoading } from "@ionic/angular/standalone";

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        IonLoading
    ]
})
export class LoaderComponent {
    @ViewChild(IonLoading)
    loading!: IonLoading;

    loader!: ILoader;

    constructor(
        private _loaderService: LoaderService
    ) {
        this._loaderService
            .getLoader()
            .pipe(
                takeUntilDestroyed(),
                tap(loader => this.loader = loader),
                filter(loader => !loader.show),
                tap(() => this.loading?.dismiss())
            ).subscribe();
    }
}
