import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { ILoader } from "./loader.interface";

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    private _loader$ = new BehaviorSubject<ILoader>({show: false});

    private _loader: ILoader = {show: false};

    private _counter = 0;

    getLoader(): Observable<ILoader> {
        return this._loader$.asObservable();
    }

    setLoader(value: ILoader): void {
        this._loader = value;
        this._loader$.next(this._loader);
    }

    showLoader(): void {
        this._counter += 1;
        this._loader.show = true;
        this._loader$.next(this._loader);
    }

    hideLoader(): void {
        this._counter -= 1;
        this._loader.show = this._counter > 0;
        this._loader$.next(this._loader);
    }
}
