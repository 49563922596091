import { Routes } from '@angular/router';
import { authGuard } from "./core/guards/auth.guard";
import { noAuthGuard } from "./core/guards/no-auth.guard";

export const routes: Routes = [
    {
        path: '',
        canActivate: [authGuard],
        loadComponent: () => import('./core/layout/private/private.component').then((m) => m.PrivateComponent),
        children: [
            {
                path: 'home',
                loadComponent: () => import('./pages/home/home.page').then((m) => m.HomePage),
            },
            {
                path: 'companies',
                loadComponent: () => import('./pages/companies/companies.page').then((m) => m.CompaniesPage),
            },
            {
                path: 'companies/new',
                loadComponent: () => import('./pages/companies-detail/companies-detail.page').then((m) => m.CompaniesDetailPage),
            },
            {
                path: 'companies/detail',
                loadComponent: () => import('./pages/companies-detail/companies-detail.page').then((m) => m.CompaniesDetailPage),
            },
            {
                path: 'users',
                loadComponent: () => import('./pages/users/users.page').then(m => m.UsersPage)
            },
            {
                path: 'users/new',
                loadComponent: () => import('./pages/users-detail/users-detail.page').then(m => m.UsersDetailPage)
            },
            {
                path: 'users/:userId',
                loadComponent: () => import('./pages/users-detail/users-detail.page').then(m => m.UsersDetailPage)
            },
            {
                path: 'sites',
                loadComponent: () => import('./pages/sites/sites.page').then(m => m.SitesPage)
            },
            {
                path: 'sites/new',
                loadComponent: () => import('./pages/sites-detail/sites-detail.page').then(m => m.SitesDetailPage)
            },
            {
                path: 'sites/:siteId',
                loadComponent: () => import('./pages/sites-detail/sites-detail.page').then(m => m.SitesDetailPage)
            },
            {
                path: 'teams',
                loadComponent: () => import('./pages/teams/teams.page').then(m => m.TeamsPage)
            },
            {
                path: 'teams/new',
                loadComponent: () => import('./pages/teams-detail/teams-detail.page').then(m => m.TeamsDetailPage)
            },
            {
                path: 'teams/:teamId',
                loadComponent: () => import('./pages/teams-detail/teams-detail.page').then(m => m.TeamsDetailPage)
            },
            {
                path: 'companies/new',
                loadComponent: () => import('./pages/companies-detail/companies-detail.page').then(m => m.CompaniesDetailPage)
            },
            {
                path: 'shift',
                loadComponent: () => import('./pages/shift/shift.page').then(m => m.ShiftPage)
            },
            {
                path: 'shift/new',
                loadComponent: () => import('./pages/shift-detail/shift-detail.page').then(m => m.ShiftDetailPage)
            },
            {
                path: 'shift/:shiftId',
                loadComponent: () => import('./pages/shift-detail/shift-detail.page').then(m => m.ShiftDetailPage)
            },
            {
                path: 'schedules',
                loadComponent: () => import('./pages/schedules/schedules.page').then(m => m.SchedulesPage)
            },
            {
                path: 'schedules/new',
                loadComponent: () => import('./pages/schedules-detail/schedules-detail.page').then(m => m.SchedulesDetailPage)
            },
            {
                path: 'access-devices',
                loadComponent: () => import('./pages/access-devices/access-devices.page').then(m => m.AccessDevicesPage)
            },
            {
                path: 'access-devices/new',
                loadComponent: () => import('./pages/access-devices-detail/access-devices-detail.page').then(m => m.AccessDevicesDetailPage)
            },
            {
                path: 'people',
                loadComponent: () => import('./pages/people/people.page').then(m => m.PeoplePage)
            },
            {
                path: 'people/new',
                loadComponent: () => import('./pages/people-detail/people-detail.page').then(m => m.PeopleDetailPage)
            },
            {
                path: 'people/:idPerson',
                loadComponent: () => import('./pages/people-detail/people-detail.page').then(m => m.PeopleDetailPage)
            },
            {
                path: 'monitor-device',
                loadComponent: () => import('./pages/monitor-device/monitor-device.page').then(m => m.MonitorDevicePage)
            },
            {
                path: 'monitor-device/new',
                loadComponent: () => import('./pages/monitor-device-detail/monitor-device-detail.page').then(m => m.MonitorDeviceDetailPage)
            },
            {
                path: 'monitor-device/:monitorDeviceId',
                loadComponent: () => import('./pages/monitor-device-detail/monitor-device-detail.page').then(m => m.MonitorDeviceDetailPage)
            },
            {
                path: 'records',
                loadComponent: () => import('./pages/records/records.page').then(m => m.RecordsPage)
            },
            {
                path: 'records/new',
                loadComponent: () => import('./pages/records-detail/records-detail.page').then(m => m.RecordsDetailPage)
            },
            {
                path: 'blacklist',
                loadComponent: () => import('./pages/blacklist/blacklist.page').then(m => m.BlacklistPage)
            },
            {
                path: 'blacklist/new',
                loadComponent: () => import('./pages/blacklist-detail/blacklist-detail.page').then(m => m.BlacklistDetailPage)
            },
            {
                path: 'blacklist/:blacklistId',
                loadComponent: () => import('./pages/blacklist-detail/blacklist-detail.page').then(m => m.BlacklistDetailPage)
            },
            {
                path: 'notifications',
                loadComponent: () => import('./pages/notifications/notifications.page').then(m => m.NotificationsPage)
            },
            {
                path: 'notifications/new',
                loadComponent: () => import('./pages/notifications-detail/notifications-detail.page').then(m => m.NotificationsDetailPage)
            },
            {
                path: 'exports',
                loadComponent: () => import('./pages/exports/exports.page').then(m => m.ExportsPage)
            }
        ],
    },
    {
        path: '',
        canActivate: [noAuthGuard],
        loadComponent: () => import('./core/layout/public/public.component').then((m) => m.PublicComponent),
        children: [
            {
                path: 'login',
                loadComponent: () => import('./pages/login/login.page').then((m) => m.LoginPage)
            },
        ]
    },
    {
        path: '**',
        redirectTo: 'login',
        pathMatch: 'full',
    }
];
