import { CanActivateFn, Router } from '@angular/router';
import { inject } from "@angular/core";
import { AuthService } from "../services/auth/auth.service";

export const noAuthGuard: CanActivateFn = (route, state) => {
    const auth = inject(AuthService);
    const router = inject(Router);
    if (!auth.isLogged()) {
        return true;
    } else {
        return router.navigateByUrl('/home');
    }
};
