import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { StorageService } from '../storage/storage.service';
import { environment } from "../../../../environments/environment";
import { ISession } from "./interfaces";

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private _http: HttpClient,
        private _router: Router,
        private _storage: StorageService
    ) {
    }

    login(data: any): Observable<ISession> {
        return this._http.post<ISession>(`${environment.apiUrl}/auth/sign-in`, data);
    }

    logout(): void {
        this._storage.removeItem('token');
        this._storage.removeItem('refreshToken');
        this._router.navigateByUrl('/login');
    }

    refreshToken(data: any): Observable<any> {
        return new Observable((observer) => {
            observer.next('token');
        });
    }

    isLogged(): boolean {
        return !!this._storage.getItem('token');
    }

    setToken(token: string): void {
        this._storage.setItem('token', token);
    }

    setRefreshToken(refreshToken: string): void {
        this._storage.setItem('refreshToken', refreshToken);
    }

    getToken(): string {
        return this._storage.getItem('token') || '';
    }

    getRefreshToken(): string {
        return this._storage.getItem('refreshToken') || '';
    }
}
