import { ApplicationConfig } from '@angular/core';
import { provideRouter, RouteReuseStrategy, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { tokenInterceptor } from "./core/interceptors/token/token.interceptor";
import { IonicRouteStrategy } from "@ionic/angular";
import { provideIonicAngular } from "@ionic/angular/standalone";
import { loaderInterceptor } from "./core/interceptors/loader/loader.interceptor";

export const appConfig: ApplicationConfig = {
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        provideIonicAngular(),
        provideRouter(routes, withComponentInputBinding()),
        provideHttpClient(withFetch(), withInterceptors([
            tokenInterceptor,
            loaderInterceptor
        ])),
    ],
};
